import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AvaliacaoExperienciaService } from 'src/app/m_rh/services/avaliacao-experiencia.service';

@Injectable({
  providedIn: 'root',
})
export class AvaliacaoExperienciaEditResolverService {
  constructor(private avaliacaoExperienciaService: AvaliacaoExperienciaService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
    let idAvaliacaoExperiencia: any = route.paramMap.get('id');
    let idAlunoCurso: any = route.paramMap.get('idAlunoCurso');
    return this.avaliacaoExperienciaService.loadDadosProva(idAvaliacaoExperiencia, idAlunoCurso);
  }
}
