export class AvaliacaoExperienciEditDTO {
  id: number;
  nomeFuncionario: string;
  idSituacaoCursoFuncionario: number;
  nomeSituacaoCursoFuncionario: string;
  nomeSupervisor: string;
  idSituacaoAvaliacaoExperiencia: number;
  nomeSituacaoCursoSupervisor: string;
  idSituacaoCursoSupervisor: number;
  nomeSituacaoAvaliacaoExperiencia: string;
  prazo: string;
  nomeUsuarioCriacao: string;
  dataCriacao: Date;
  nomeUsuarioAtualizacao: string;
  dataAtualizacao: Date;
  motivo: string;
  aprovado: boolean;

  constructor(bean?: any) {
    if (bean.id) this.id = bean.id;
    if (bean.nomeFuncionario) this.nomeFuncionario = bean.nomeFuncionario;
    if (bean.idSituacaoCursoFuncionario) this.idSituacaoCursoFuncionario = bean.idSituacaoCursoFuncionario;
    if (bean.nomeSituacaoCursoFuncionario) this.nomeSituacaoCursoFuncionario = bean.nomeSituacaoCursoFuncionario;
    if (bean.nomeSupervisor) this.nomeSupervisor = bean.nomeSupervisor;
    if (bean.idSituacaoCursoSupervisor) this.idSituacaoCursoSupervisor = bean.idSituacaoCursoSupervisor;
    if (bean.nomeSituacaoCursoSupervisor) this.nomeSituacaoCursoSupervisor = bean.nomeSituacaoCursoSupervisor;
    if (bean.idSituacaoAvaliacaoExperiencia)
      this.idSituacaoAvaliacaoExperiencia = bean.idSituacaoAvaliacaoExperiencia;
    if (bean.nomeSituacaoAvaliacaoExperiencia)
      this.nomeSituacaoAvaliacaoExperiencia = bean.nomeSituacaoAvaliacaoExperiencia;
    if (bean.prazo) this.prazo = bean.prazo;
    if (bean.nomeUsuarioCriacao) this.nomeUsuarioCriacao = bean.nomeUsuarioCriacao;
    if (bean.dataCriacao) this.dataCriacao = bean.dataCriacao;
    if (bean.nomeUsuarioAtualizacao) this.nomeUsuarioAtualizacao = bean.nomeUsuarioAtualizacao;
    if (bean.dataAtualizacao) this.dataAtualizacao = bean.dataAtualizacao;
    if (bean.motivo) this.motivo = bean.motivo;
    if (bean.aprovado != null) this.aprovado = bean.aprovado;
  }
}
