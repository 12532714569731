import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { map, catchError } from 'rxjs/operators';
import { ConfigCliente } from 'src/app/_config-cliente/config-cliente';
import { throwError } from 'rxjs';
import { AvaliacaoExperienciaList } from '../model/avaliacao-experiencia-list';
import { AlunoProvaDTO } from '../pages/avaliacao-experiencia/models/AlunoProvaDTO';

const MODULO = 'pwa/rh';
const CLASSE = '/avaliacao-experiencia/';
const PATH_CLASSE = ConfigCliente.APP_REST() + MODULO + CLASSE;
const LOADDADOSPROVA = PATH_CLASSE + 'load-dados-prova';
const INICIARAVALIACAO = PATH_CLASSE + 'iniciar-avaliacao';
const LISTARLAZY = PATH_CLASSE + 'listar-avaliacoes-por-usuario';

@Injectable({
  providedIn: 'root',
})
export class AvaliacaoExperienciaService {
  constructor(private httpClient: HttpClient, private paramUtilService: ParamUtilService) {}

  concluirAvaliacao(bean: AlunoProvaDTO) {
    return this.httpClient.put<RetornoResponse>(PATH_CLASSE, bean, {}).pipe(
      map((res) => {
        if (res.codigo == 1 || res.retorno == null) throw res.mensagem;
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*LIST*/
  listarLazy() {
    return this.httpClient.post<RetornoResponse>(LISTARLAZY, null, {}).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  iniciarAvaliacao(idAlunoCurso: number) {
    return this.httpClient.put<RetornoResponse>(INICIARAVALIACAO + '/' + idAlunoCurso, null, {}).pipe(
      map((res) => {
        if (res.codigo == 1) throw res.mensagem;
        return res.retorno;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*LOAD*/
  loadDadosProva(idAvaliacaoExperiencia: number, idAlunoCurso: number) {
    return this.httpClient
      .get<RetornoResponse>(`${LOADDADOSPROVA}/${idAvaliacaoExperiencia}/${idAlunoCurso}`)
      .pipe(
        map((res) => {
          if (res.codigo == 1) throw res.mensagem;
          return res.retorno;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
