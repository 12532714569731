import { ObjectUtil } from 'src/app/util/service/object-util.service';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { FormUtil } from 'src/app/util/service/form-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageUtilService } from 'src/app/util/service/message-util.service';
import { Labels } from 'src/app/core/constant/labels';
import { Component, OnInit } from '@angular/core';
import { DocumentoCandidatoEdit } from 'src/app/m_rh/pages/pre-cadastro/model/documento-candidato-edit';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosComponent implements OnInit {
  cfg: any = {};
  subscription: Subscription = new Subscription();
  listaDocumentosCandidato: DocumentoCandidatoEdit[];
  itemSelecionado: DocumentoCandidatoEdit;
  form: FormGroup;
  maxSizeAnexo = 5120000; //5 mb em bytes
  cpf: string;

  constructor(
    public lbs: Labels,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private inputUtil: InputUtilService,
    public msgUtil: MessageUtilService,
    private loadingService: LoadingService,
    private candidatoVagaHttpService: CandidatoVagaHttpService
  ) {}

  ngOnInit() {
    this.carregarPagina();
  }

  carregarPagina(): void {
    /*LÓGICA DE CARREGAMENTO DA PÁGINA*/
    this.subscription.add(
      this.activatedRoute.data.subscribe(
        (data: { dataResolver: any }) => {
          this.listaDocumentosCandidato = data.dataResolver[0];
          this.cpf = data.dataResolver[1];
          //
          this.loadingService.off();
          this.carregarForm();
        },
        (error) => {
          this.loadingService.off();
        }
      )
    );
  }

  //EDIT

  selecionar(item: DocumentoCandidatoEdit): void {
    this.itemSelecionado = item;
    this.carregarForm();
    this._preencherDocumentosCpf();
  }

  salvarItemSelecionado(): void {
    if (this.validarForm()) {
      this.loadingService.on();
      let bean = this.form.value;
      this.itemSelecionado.documento = bean;
      //console.log(this.itemSelecionado);
      this.candidatoVagaHttpService.salvarDocumentoCandidato(this.itemSelecionado, this.idVaga).subscribe(
        (next: RetornoResponse) => {
          //console.log(next);
          this.loadingService.off();
          this.msgUtil.sucessPop(next.mensagem);
          this.itemSelecionado = null;
          this.listarItens();
        },
        (error) => {
          this.msgUtil.error(error);
          //this._warning(error, true, false);
          this.loadingService.off();
        },
        () => {
          //this.loadingService.off();
        }
      );
    }
  }

  listarItens(): void {
    this.loadingService.on();
    this.candidatoVagaHttpService.listarDocumentosVaga(this.idVaga).subscribe((next) => {
      this.loadingService.off();
      this.listaDocumentosCandidato = next;
    });
  }

  //FORM

  //prettier-ignore
  carregarForm(): void {
    this.carregarConfig();//CARREGA AS CONFIGURAÇÕES DOS CAMPOS ANTES DE CRIAR O FORM, POIS ESTE PODE DEPENDER DOS VALORES DE CONFIGURAÇÃO
    this.form = this.formBuilder.group({ //PARA A INTEGRAÇÃO ENTRE O BEAN E OS CONTROLS FUNCIONAREM O FORMCONTROLNAME DEVEM POSSUIR O MESMO NOME DO CAMPO DO BEAN AO QUAL ELE CORRESPONDE.
        numero: [null, [Validators.required]],
        serie: [null, []],
        estadoEmissao: [null],
        dataEmissao: [null],
        // complemento: [null],
        dataValidade: [null],
    });
    if (this.cfg.orgaoEmissor())
      this.form.addControl('orgaoEmissor', this.formBuilder.control(null, [Validators.required]));
    //this.form.setValue(this.json_teste);
  }

  validarForm(): boolean {
    //pristine é o contrário do dirty
    let hoje = new Date();
    let doc = this.form.value;
    let dataValidade = null;
    let dataEmissao = null;
    if (typeof doc.dataValidade == 'string' || doc.dataValidade instanceof String) {
      let dataParts = doc.dataValidade.split('/');
      dataValidade = new Date(dataParts[1] + '/' + dataParts[0] + '/' + dataParts[2]);
    } else {
      dataValidade = doc.dataValidade;
    }
    if (typeof doc.dataEmissao == 'string' || doc.dataEmissao instanceof String) {
      let dataParts = doc.dataEmissao.split('/');
      dataEmissao = new Date(dataParts[1] + '/' + dataParts[0] + '/' + dataParts[2]);
    } else {
      dataEmissao = doc.dataEmissao;
    }

    if (this.form.invalid) {
      FormUtil.markAllAsDirty(this.form);
      this.msgUtil.warn(this.lbs.m.formInvalido);
      return false;
    }
    if (!this.cfg.btnAnexo() && this.itemSelecionado.foto == null) {
      this.msgUtil.warn('Adicione a Foto da FRENTE do Documento.');
      return false;
    }
    if (this.cfg.btnVerso() && this.itemSelecionado.fotoVerso == null) {
      this.msgUtil.warn('Adicione a Foto do VERSO do Documento.');
      return false;
    }
    if (this.cfg.btnAnexo() && this.itemSelecionado.anexo == null) {
      this.msgUtil.warn('Adicione o ANEXO do Documento.');
      return false;
    }
    if (dataEmissao == null && dataValidade != null) {
      this.msgUtil.warn('A DATA DE EMISSÃO de um documento com validade não pode ser vázia.');
      return false;
    }

    if (dataEmissao != null && dataEmissao.getTime() > hoje.getTime()) {
      this.msgUtil.warn('A DATA DE EMISSÃO não pode ocorrer no futuro.');
      return false;
    }
    if (dataValidade != null && dataValidade.getTime() < dataEmissao.getTime()) {
      this.msgUtil.warn('A DATA DE VALIDADE não ser inferior á data de emissão.');
      return false;
    }
    if (this.cfg.placa() && !this.validarPlaca(this.form.get('numero').value)) {
      this.msgUtil.warn('A Placa informada não segue um padrão reconhecido.');
      return false;
    }
    return true;
  }
  validarPlaca(placa: string) {
    const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/;
    const regexPlacaMercosulCarro = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;
    if (regexPlaca.test(placa) || regexPlacaMercosulCarro.test(placa)) {
      return true;
    } else {
      return false;
    }
  }
  //prettier-ignore
  carregarConfig(): void {    
    /**VAI CONTROLAR OS CAMPOS QUE SERÃO ESCONDIDOS/MOSTRADOS NA FORMA DE TEXTO OU EM INPUTS: NULL (ESCONDE), TRUE (MOSTRA INPUT), FALSE (MOSTRA TEXTO)*/
    this.cfg = {
      /*BOTAO*/
      btnPreencher: (item: DocumentoCandidatoEdit) => item.idDocumentoCandidato == null,
      btnCorrigir: (item: DocumentoCandidatoEdit) => item.idDocumentoCandidato != null && item.aprovado == false,
      btnVerso: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.frenteVerso:false,
      btnAnexo: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.anexo:false,
      /*INPUT*/
      frenteVerso: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.frenteVerso:false,
      anexo: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.anexo:false,
      dataEmissao: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.dataEmissao:false,
      estadoEmissao: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.estadoEmissao:false,
      serie: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.serie:false,
      orgaoEmissor: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.orgaoEmissor:false,
      dataValidade: () => this.itemSelecionado?this.itemSelecionado.tipoDocumento.dataValidade:false,
      placa: () => this.itemSelecionado&&this.itemSelecionado.tipoDocumento.tipoDocumentoVeiculo?this.itemSelecionado.tipoDocumento.tipoDocumentoVeiculo.isPlaca:false,
      /*CONDICAO*/
      msgAguardando: (item: DocumentoCandidatoEdit) => item.idDocumentoCandidato != null && item.aprovado == null,
      msgAprovado: (item: DocumentoCandidatoEdit) => item.idDocumentoCandidato != null && item.aprovado == true,
      /**... */
    };
  }

  //FOTO

  selecionarFoto(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemSelecionado.foto = result || reader.result;
        this.itemSelecionado.fotoName = name || file.name;
      });
    };
    reader.readAsDataURL(file);
  }

  selecionarFotoVerso(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemSelecionado.fotoVerso = result || reader.result;
        this.itemSelecionado.fotoVersoName = name || file.name;
      });
    };
    reader.readAsDataURL(file);
  }

  selecionarAnexo(event) {
    let file = event.target.files[0];
    if (file.size > this.maxSizeAnexo) {
      this.msgUtil.warn(
        'O tamanho do arquivo é maior que 5MB, favor inserir um documento no tamanho determinado.'
      );
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.itemSelecionado.anexo = reader.result;
      this.itemSelecionado.anexoName = file.name;
    };
  }

  resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    callback: (name: string, result?: string | ArrayBuffer) => void
  ) {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      if (width <= maxWidth && height <= maxHeight) {
        callback(file.name);
      }
      let newWidth;
      let newHeight;
      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }
      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, newWidth, newHeight);
      var finalFile = canvas.toDataURL(file.type);
      let reader2 = new FileReader();
      canvas.toBlob(function (blob) {
        reader2.readAsDataURL(blob);
      }, file.type);
      reader2.onloadend = () => {
        callback(file.name, reader2.result);
      };
    };
  }

  //OUTROS

  voltarParaMenu(): void {
    this.router.navigate(['../menu'], { relativeTo: this.activatedRoute });
  }

  //LOCAL

  private get idVaga(): number {
    return Number.parseInt(sessionStorage.getItem('idVaga'));
  }

  /**VAI IDENTIFICAR SE É UM DOCUMENTO QUE DEVE SER PREENCHIDO COM O VALOR DO CPF DA PESSOA, UTILIZADO SOMENTE SE O NÚMERO AINDA NÃO EXISTIR */
  private _preencherDocumentosCpf(): void {
    if (this.itemSelecionado.tipoDocumento.preencherComCpf) {
      this.form.controls['numero'].setValue(this.cpf);
      //e.documento.numero = this.cpf;
    }
  }
  isDocVeiculo(item: any) {
    return item.tipoDocumento.tipoDocumentoVeiculo != null && item.tipoDocumento.tipoDocumentoVeiculo.isPlaca;
  }

  isAlfanumerico(item: any) {
    return item.tipoDocumento.permiteCaractere != null && item.tipoDocumento.permiteCaractere;
  }
}
