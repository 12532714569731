import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LazyLoadEvent, ConfirmationService } from 'primeng/api';

import { Labels } from 'src/app/core/constant/labels';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';
import { Mensagens } from 'src/app/core/constant/messages';
import { AvaliacaoExperienciaService } from 'src/app/m_rh/services/avaliacao-experiencia.service';
import { AvaliacaoExperienciaList } from 'src/app/m_rh/model/avaliacao-experiencia-list';
import { LoadingService } from 'src/app/_util/loading/loading.service';

@Component({
  selector: 'app-avaliacao-experiencia-list',
  templateUrl: './avaliacao-experiencia-list.component.html',
  styleUrls: ['./avaliacao-experiencia-list.component.scss'],
})
export class AvaliacaoExperienciaListComponent implements OnInit {
  lazyLoadEvent: LazyLoadEvent;
  size: number;
  lista: AvaliacaoExperienciaList[];
  itemSelecionado: AvaliacaoExperienciaList;

  constructor(
    public lbs: Labels,
    private confirmationService: ConfirmationService,
    private avaliacaoExperienciaService: AvaliacaoExperienciaService,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private loadingService: LoadingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.listar();
  }

  listar(): void {
    this.loadingService.onLoading(true);
    this.avaliacaoExperienciaService.listarLazy().subscribe(
      (next) => {
        this.lista = next;
        this.loadingService.onLoading(false);
      },
      (error) => {
        this.msgGrowl.showWarn(this.msg.msg_warn, error);
        this.loadingService.onLoading(false);
      },
      () => {
        this.loadingService.onLoading(false);
      }
    );
  }

  listarLazy(event: LazyLoadEvent): void {
    this.loadingService.onLoading(true);
    this.lazyLoadEvent = event;
    this.avaliacaoExperienciaService.listarLazy().subscribe(
      (next) => {
        this.lista = next[0];
        this.size = next[1];
        this.loadingService.onLoading(false);
      },
      (error) => {
        this.msgGrowl.showWarn(this.msg.msg_warn, error);
        this.loadingService.onLoading(false);
      },
      () => {
        this.loadingService.onLoading(false);
      }
    );
  }

  editar(bean: AvaliacaoExperienciaList): void {
    if (bean.dataInicio) {
      this.editarItem(bean);
    } else {
      this.confirmationService.confirm({
        message: this.lbs.avaliacaoExperiencia.m.confirmarInicio,
        accept: () => {
          this.loadingService.onLoading(true);
          this.avaliacaoExperienciaService.iniciarAvaliacao(bean.idAlunoCurso).subscribe(
            (next) => {
              this.editarItem(bean);
              this.loadingService.onLoading(false);
            },
            ({ error }) => {
              this.msgGrowl.showWarn(this.msg.msg_warn, error.mensagem);
              this.loadingService.onLoading(false);
            },
            () => {
              this.loadingService.onLoading(false);
            }
          );
        },
      });
    }
  }

  editarItem(bean: AvaliacaoExperienciaList): void {
    console.log('editar: ' + 1);
    this.router.navigate([
      '/rh/avaliacao-experiencia/edit',
      bean.id,
      bean.idAlunoCurso,
      Math.random().toString(36).substring(7),
    ]);
    console.log('editar: ' + 2);
  }
}
