import { AlunoProvaPergunta } from './AlunoProvaPergunta';
import { AvaliacaoExperienciEditDTO } from './AvaliacaoExperienciaEditDTO';

export class AlunoProvaDTO {
  idAvaliacaoExperiencia: number;
  idAlunoProva: number;
  avaliacao: AvaliacaoExperienciEditDTO;
  nomeCurso: string;
  nomeSupervisor: string;
  nomeFuncionario: string;
  listaPerguntas: AlunoProvaPergunta[];
  supervisor: boolean;
  aprovado: boolean = true;
  motivo: string;
  nomeCentroCustoFuncionario: number;
  idCentroCustoFuncionario: number;

  constructor(bean?: any) {
    if (bean) {
      if (bean.idAvaliacaoExperiencia) this.idAvaliacaoExperiencia = bean.idAvaliacaoExperiencia;
      if (bean.avaliacao) this.avaliacao = new AvaliacaoExperienciEditDTO(bean.avaliacao);
      if (bean.idAlunoProva) this.idAlunoProva = bean.idAlunoProva;
      if (bean.nomeCurso) this.nomeCurso = bean.nomeCurso;
      if (bean.nomeSupervisor) this.nomeSupervisor = bean.nomeSupervisor;
      if (bean.nomeFuncionario) this.nomeFuncionario = bean.nomeFuncionario;
      if (bean.listaPerguntas) this.listaPerguntas = bean.listaPerguntas;
      if (bean.supervisor != null) this.supervisor = bean.supervisor;
      if (bean.nomeCentroCustoFuncionario != null) this.nomeCentroCustoFuncionario = bean.nomeCentroCustoFuncionario;
      if (bean.idCentroCustoFuncionario != null) this.idCentroCustoFuncionario = bean.idCentroCustoFuncionario;
    }
  }
}
