import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Labels } from 'src/app/core/constant/labels';

@Component({
  selector: 'app-avaliacao-experiencia',
  templateUrl: './avaliacao-experiencia.component.html',
  styleUrls: ['./avaliacao-experiencia.component.scss'],
})
export class AvaliacaoExperienciaComponent implements OnInit {
  constructor(public lbs: Labels, private router: Router) {}

  ngOnInit() {
    this.router.navigate(['/rh/avaliacao-experiencia/list']);
  }
}
