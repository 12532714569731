import { RhAlterarSenhaComponent } from './pages/rh-alterar-senha/rh-alterar-senha.component';
import { ExamesResolverService } from './pages/pre-cadastro/service/exames-resolver.service';
import { ExamesComponent } from './pages/pre-cadastro/exames/exames.component';
import { MenuPreCadastroResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/menu-pre-cadastro-resolver.service';
import { PreCadastroResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/pre-cadastro-resolver.service';
import { DocumentosResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/documentos-resolver.service';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guard/auth.guard';
import { PreCadastroComponent } from 'src/app/m_rh/pages/pre-cadastro/pre-cadastro.component';
import { DadosCadastraisResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/dados-cadastrais-resolver.service';
import { AppMainComponent } from '../app.main.component';
import { DadosCadastraisComponent } from './pages/pre-cadastro/dados-cadastrais/dados-cadastrais.component';
import { DocumentosComponent } from './pages/pre-cadastro/documentos/documentos.component';
import { MenuPreCadastroComponent } from './pages/pre-cadastro/menu-pre-cadastro/menu-pre-cadastro.component';

import { LancamentoEditComponent } from './pages/lancamento/lancamento-edit/lancamento-edit.component';
import { LancamentoListComponent } from './pages/lancamento/lancamento-list/lancamento-list.component';
import { LancamentoComponent } from './pages/lancamento/lancamento.component';
import { LancamentoEditResolverService } from './pages/lancamento/resolver/lancamento-edit-resolver.service';
import { RhAlterarSenhaResolverService } from './pages/rh-alterar-senha/resolver/rh-alterar-senha-resolver.service';
import { AlterarEmailComponent } from './pages/alterar-email/alterar-email.component';
import { AlterarEmailResolverService } from './pages/alterar-email/resolver/alterar-email-resolver.service';
import { DependentesComponent } from './pages/pre-cadastro/dependentes/dependentes.component';
import { DependentesResolverService } from './pages/pre-cadastro/service/dependentes-resolver.service';
import { AvaliacaoExperienciaComponent } from './pages/avaliacao-experiencia/avaliacao-experiencia.component';
import { AvaliacaoExperienciaListComponent } from './pages/avaliacao-experiencia/avaliacao-experiencia-list/avaliacao-experiencia-list.component';
import { AvaliacaoExperienciaEditComponent } from './pages/avaliacao-experiencia/avaliacao-experiencia-edit/avaliacao-experiencia-edit.component';
import { AvaliacaoExperienciaEditResolverService } from './pages/avaliacao-experiencia/resolver/avaliacao-experiencia-edit-resolver.service';

const paginasRhRoutes: Routes = [
  {
    path: 'rh',
    component: AppMainComponent,
    children: [
      //LANCAMENTO
      {
        path: 'lancamento',
        component: LancamentoComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
          { path: 'list', component: LancamentoListComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
          { path: 'new', component: LancamentoEditComponent, canActivate: [AuthGuard], canLoad: [AuthGuard] },
          /**utilizei esse random como solução temporária para o resolver ser executado novamente sempre que voltar para a
           * lista e clicar para editar o mesmo item, como o router não identifica uma mudança de rota ele não chamado o load novamente, tentei usar o
           * runGuardsAndResolvers: 'always' mas não deu certo a princípio. Precisei forçar por causo do alerta*/
          {
            path: 'edit/:id/:random',
            component: LancamentoEditComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { editResolver: LancamentoEditResolverService },
          }, //, runGuardsAndResolvers: 'always'
        ],
      },

      //PRE-CADASTRO
      {
        path: 'pre-cadastro',
        component: PreCadastroComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        resolve: { dataResolver: PreCadastroResolverService },
        children: [
          {
            path: '',
            redirectTo: 'menu',
            pathMatch: 'full',
          },
          {
            path: 'menu',
            component: MenuPreCadastroComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { dataResolver: MenuPreCadastroResolverService },
          },
          {
            path: 'dados-cadastrais/:corrigir',
            component: DadosCadastraisComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { dataResolver: DadosCadastraisResolverService },
          },
          {
            path: 'documentos',
            component: DocumentosComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { dataResolver: DocumentosResolverService },
          },

          {
            path: 'exames',
            component: ExamesComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { dataResolver: ExamesResolverService },
          },
          {
            path: 'dependentes',
            component: DependentesComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { dataResolver: DependentesResolverService },
          },
        ],
      },
      //AVALIAÇÃO DE EXPERIÊNCIA
      {
        path: 'avaliacao-experiencia',
        component: AvaliacaoExperienciaComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
          {
            path: 'list',
            component: AvaliacaoExperienciaListComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
          },
          /**utilizei esse random como solução temporária para o resolver ser executado novamente sempre que voltar para a
           * lista e clicar para editar o mesmo item, como o router não identifica uma mudança de rota ele não chamado o load novamente, tentei usar o
           * runGuardsAndResolvers: 'always' mas não deu certo a princípio. Precisei forçar por causo do alerta*/
          {
            path: 'edit/:id/:idAlunoCurso/:random',
            component: AvaliacaoExperienciaEditComponent,
            canActivate: [AuthGuard],
            canLoad: [AuthGuard],
            resolve: { editResolver: AvaliacaoExperienciaEditResolverService },
          }, //, runGuardsAndResolvers: 'always'
        ],
      },
      //ALTERAR SENHA RH
      {
        path: 'rh-alterar-senha',
        component: RhAlterarSenhaComponent,
        resolve: { resolve: RhAlterarSenhaResolverService },
      },
      //ALTERAR EMAIL
      {
        path: 'alterar-email',
        component: AlterarEmailComponent,
        resolve: { resolve: AlterarEmailResolverService },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(paginasRhRoutes)],
  exports: [RouterModule],
})
export class PaginasRhRoutes {}
