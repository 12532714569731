import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message, ConfirmationService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Labels } from 'src/app/core/constant/labels';
import { MessageGrowlService } from 'src/app/_util/message-growl/message-growl.service';

import { LoadingService } from 'src/app/_util/loading/loading.service';
import { Mensagens } from 'src/app/core/constant/messages';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { AvaliacaoExperienciaService } from 'src/app/m_rh/services/avaliacao-experiencia.service';
import { AlunoProvaDTO } from '../models/AlunoProvaDTO';
import { Pergunta } from '../models/Pergunta';
import { FieldError } from '../models/FieldError';

const MIN_CARACTERES_JUSTIFICATIVA = 15;
const CURSO_CONCLUIDO = 3;

@Component({
  selector: 'app-avaliacao-experiencia-edit',
  templateUrl: './avaliacao-experiencia-edit.component.html',
  styleUrls: ['./avaliacao-experiencia-edit.component.scss'],
})
export class AvaliacaoExperienciaEditComponent implements OnInit {
  subscription: Subscription; //boa prática//aplicar em todos inscricoes, e fazer de uma forma mais facil com decorator como eh sugerido na WEB
  id: number;
  bean: AlunoProvaDTO = new AlunoProvaDTO(null);
  tempMsg: Message[] = [];
  formularioPreenchido: boolean = false;

  //FORM
  classStyleColumn: string = 'p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12 my-classStyleColumn p-nogutter';
  classStyleRow: string = 'p-col-12 p-grid';
  classStyleLabel: string = 'p-col-fixed my-classStyleLabel'; //'p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).
  classStyleField: string = 'p-col'; //'p-col-8 p-xl-8 p-lg-8 p-md-8 p-sm-12';//colunas com tamanhos não fixo (DEFAULT).

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public lbs: Labels,
    private msgGrowl: MessageGrowlService,
    private msg: Mensagens,
    private loadingService: LoadingService,
    private avaliacaoExperienciaService: AvaliacaoExperienciaService
  ) {}

  /*NG*/
  ngOnInit() {
    this.editar();
  }

  ngOnDestroy() {
    if (this.subscription != null) this.subscription.unsubscribe; //boa prática
  }

  validarBean(): boolean {
    let valido: boolean = true;

    this.bean.listaPerguntas.forEach((alunoProvaPergunta) => {
      const pergunta = alunoProvaPergunta.pergunta;
      pergunta.errosJustificativa = [];
      pergunta.errosQuestao = [];

      const dissertativa = pergunta.dissertativa;
      //Validando resposta
      if (!dissertativa && !pergunta.idResposta) {
        pergunta.errosQuestao.push(new FieldError(this.lbs.avaliacaoExperiencia.m.respostaObrigatoria));
        valido = false;
      }
      if (dissertativa && !pergunta.respostaDissertativa) {
        pergunta.errosQuestao.push(new FieldError(this.lbs.avaliacaoExperiencia.m.respostaObrigatoria));
      }
      //Resposta com mínimo de caracteres
      if (
        dissertativa &&
        pergunta.respostaDissertativa &&
        pergunta.respostaDissertativa.length < MIN_CARACTERES_JUSTIFICATIVA
      ) {
        pergunta.errosQuestao.push(new FieldError(this.lbs.avaliacaoExperiencia.m.respostaCurta));
      }
      //Validando justificativa
      if (this.exibirJustificativa(pergunta)) {
        if (!pergunta.justificativa) {
          pergunta.errosJustificativa.push(
            new FieldError(this.lbs.avaliacaoExperiencia.m.justificativaObrigatoria)
          );
          valido = false;
        }
        if (pergunta.justificativa && pergunta.justificativa.length < MIN_CARACTERES_JUSTIFICATIVA) {
          pergunta.errosJustificativa.push(new FieldError(this.lbs.avaliacaoExperiencia.m.justificativaCurta));
          valido = false;
        }
      }
    });

    if (!valido) {
      valido = false;
      this.mostrarMensagem(2, this.msg.msg_form_invalido, true, true);
    } else {
      if (this.router.url.includes('/edit')) {
        console.log(this.bean);
        this.confirm();
      }
    }
    return valido;
  }

  confirm() {
    if (this.bean.supervisor) {
      this.confirmationService.confirm({
        key: 'confirmAprovar',
        message: this.lbs.avaliacaoExperiencia.m.aprovarFuncionario.replace('{0}', this.bean.nomeFuncionario),
        accept: () => {
          if (!this.bean.aprovado) {
            if (!this.bean.motivo) {
              this.mostrarMensagem(2, this.lbs.avaliacaoExperiencia.m.motivoObrigatorio, true, true);
              return;
            }
            if (this.bean.motivo && this.bean.motivo.length < MIN_CARACTERES_JUSTIFICATIVA) {
              this.mostrarMensagem(2, this.lbs.avaliacaoExperiencia.m.motivoObrigatorio, true, true);
              return;
            }
          }
          this.salvar();
        },
      });
    } else {
      this.salvar();
    }
  }

  salvar(): void {
    this.loadingService.onLoading(true);
    this.avaliacaoExperienciaService.concluirAvaliacao(this.bean).subscribe(
      (next: RetornoResponse) => {
        this.mostrarMensagem(0, next.mensagem, true, false);
        this.loadingService.onLoading(false);
        this.voltar();
      },
      (error) => {
        this.mostrarMensagem(2, error, true, true);
        this.loadingService.onLoading(false);
      },
      () => {
        this.loadingService.onLoading(false);
      }
    );
  }

  editar(): void {
    if (this.router.url.includes('/edit')) {
      /**LOAD É FEITO ATRAVÉS DO RESOLVER QUE É CARREGADO ANTES DO COMPONENTE SE INICIALIZADO */
      this.subscription = this.activatedRoute.data.subscribe((data: { editResolver: any }) => {
        if (data.editResolver != null) {
          this.bean = new AlunoProvaDTO(data.editResolver);
          this.formularioPreenchido = !this.bean.supervisor
            ? this.bean.avaliacao.idSituacaoCursoFuncionario == CURSO_CONCLUIDO
            : this.bean.avaliacao.idSituacaoCursoSupervisor == CURSO_CONCLUIDO;
          console.log(this.bean);
        } else {
          this.mostrarMensagem(2, `${this.msg.msg_registro_naoexiste} ${this.id}.`, true, true);
        }
      });
    }
  }

  voltar() {
    history.back();
  }

  /**0-sucess, 1-info, 2-warn, 3-error*/
  mostrarMensagem(severity: number, detail: string, limpar: boolean, mostrarFixa: boolean): void {
    const _severity: string =
      severity == 0 ? 'success' : severity == 1 ? 'info' : severity == 2 ? 'warn' : 'error';
    const summary: string =
      severity == 0
        ? this.msg.msg_success
        : severity == 1
        ? this.msg.msg_info
        : severity == 2
        ? this.msg.msg_warn
        : this.msg.msg_error;
    if (limpar) this.tempMsg = [];
    if (mostrarFixa) this.tempMsg.push({ severity: _severity, summary: summary, detail: detail });
    switch (severity) {
      case 0:
        this.msgGrowl.showSuccess(summary, detail);
        break;
      case 1:
        this.msgGrowl.showInfo(summary, detail);
        break;
      case 2:
        this.msgGrowl.showWarn(summary, detail);
        break;
      case 3:
        this.msgGrowl.showError(summary, detail);
        break;
      default:
        this.msgGrowl.showWarn(summary, detail);
        break;
    }
  }

  exibirJustificativa(pergunta: Pergunta) {
    let opcaoObrigaJustificativa = false;
    const opcaoResposta = !pergunta.dissertativa ? pergunta.idResposta : null;
    if (opcaoResposta != null) {
      const opcaoSelecionada = pergunta.respostasList.find((opcao) => opcao.id == opcaoResposta);
      if (opcaoSelecionada != null) {
        opcaoObrigaJustificativa = opcaoSelecionada.justificativaObrigatoria;
      }
    }
    if (pergunta.justificativaObrigatoria || opcaoObrigaJustificativa) {
      return true;
    } else {
      pergunta.justificativa = null;
      return false;
    }
  }
}
