export class Mensagens {

    readonly label_aplicacao = 'arena:go';

    readonly msg_success = 'SUCESSO: ';
    readonly msg_warn = 'ALERTA:';
    readonly msg_info = 'INFO:';
    readonly msg_error = 'FALHA:';
    
    readonly msg_error_conexao = 'OCORREU UMA FALHA NA COMUNICAÇÃO.';
    readonly msg_form_vazio = 'Existem campos obrigatórios vazios.';
    readonly msg_form_invalido = 'Existem campos preenchidos incorretamente, favor verificar os campos.';
    readonly msg_form_emailsInvalidos = 'Não foi possível concluir está ação. Um ou mais emails inválidos foram infomados: ';
    readonly msg_registro_naoexiste = 'Nenhum registro encontrado este ID ';    

    /**relatorioMapaChamado */
    readonly msg_chm_relMapaChamado_periodoMaximo = 'O período máximo de consulta permitido é de 90 dias.';

    /**ESTOQUE */

    /**movimentacaopatrimonio */
    readonly msg_stq_movimentacaoPatrimonio_falhaCopia = 'Não foi possível fazer a cópia desta movimentação de patrimônio.';

}
